import React, { useEffect, useState } from 'react';
import fire from './firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [emailError, setEmailError] = useState('test');
  const [passwordError, setPasswordError] = useState('testpswed');

  const clearErrors = () => {
    setEmailError('');
    setPasswordError('');
  };

  const handleLogOut = () => {
    fire.auth().signOut();
  };

  useEffect(() => {
    fire.auth().onAuthStateChanged(setCurrentUser);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        clearErrors,
        currentUser,
        handleLogOut,
        emailError,
        setEmailError,
        passwordError,
        setPasswordError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
