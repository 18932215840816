export const firebaseConfig = {
  apiKey: 'AIzaSyCYhBvpckcRXHrkX6-eH4hu0XjFN5zTGqE',
  authDomain: 'redroot-473e2.firebaseapp.com',
  databaseURL: 'https://redroot-473e2.firebaseio.com',
  projectId: 'redroot-473e2',
  storageBucket: 'redroot-473e2.appspot.com',
  messagingSenderId: '772816281105',
  appId: '1:772816281105:web:eb08a833470a0240bd1bb0',
  measurementId: 'G-NY10RBDRHT',
};
