import React from 'react';

const Benefits = () => {
  return (
    <div>
      <h2>I am the benefits</h2>
    </div>
  );
};

export default Benefits;
